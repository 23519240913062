import Vue from 'vue'
import { getCheckCode, register } from '../../api/registerApi'
import { mapActions } from 'vuex'
import Clipboard from 'clipboard'
import { Dialog } from 'vant'
import { CountDown } from 'vant'
import { Toast } from 'vant'
import { Popup } from 'vant'
import { ActionSheet } from 'vant'
import { Picker } from 'vant'
import { RadioGroup, Radio, Cell, CellGroup } from 'vant'
import { ImagePreview } from 'vant'

// 全局注册
Vue.use(ImagePreview)
Vue.use(Cell)
Vue.use(CellGroup)
Vue.use(Radio)
Vue.use(RadioGroup)
Vue.use(Picker)
Vue.use(ActionSheet)
Vue.use(Popup)
Vue.use(Toast)
// 全局注册
Vue.use(CountDown)
Vue.use(Dialog)

export default {
  components: {},
  data () {
    return {
      detail: {},
      a: '',
      //弹出层
      show: false,
      radio: '1',
      //积分
      userAccountBanlance: '',
      time: 24 * 60 * 60 * 1000,
      noNeed: true,
      //需
      need: false,
      //支付方式
      Payment: true,
      //积分
      integral: true,
      //快递
      Package: true,
      // findWu:false,
      pay: false,
      again: true,
      fix: false,
      cancel: false,
      confirm: false,
      lists: [],
      name: '',
      phone: '',
      address: '',
      productName: '',
      productPrice: '',
      flChildOrderNumber: '',
      flChildOrderNo: '',
      flChildOrderPayTime: '',
      flChildOrderTotalAmount: '',
      flChildOrderState: '',
      flOrderPayType: '',
      jiFen: '',
      lastMoney: '',
      flChildOrderLogistics: '',
      flProductHomeImage: '',
      OrderState: '',
      flChildOrderId: '',
      flChildOrderExpress: '',
      flProductId: ''

    }
  },
  created () {
    this.getDetail()


  },

  methods: {
    ...mapActions('warranty', ['myFlOrder', 'queryorder', 'listVoResult', 'sureOrder']),
    ...mapActions('shopList', ['addCart', 'checkShop1', 'checkbyId', 'subOrder']),
    back () {
      if (this.$route.query.orderBack) {
        this.$router.push({ name: 'myOrder' })
      } else if (this.$route.query.back == 3) {
        this.$router.push({ name: 'myOrder' })
      }
      else {
        // this.$router.go(-1);
        this.$router.push({ name: 'myOrder' })
        localStorage.setItem('active', this.$route.query.name)
      }


    },

    CopyUrl (flChildParentOrderNo) {
      //调用copy函数
      this.copy(flChildParentOrderNo)
    },

    sure () {
      Dialog.confirm({
        confirmButtonText: '确认收货',
        message: '确认收到货了吗？',
      })
        .then(() => {
          // on confirm
          var result = {
            flChildOrderId: this.flChildOrderId,
            flChildOrderState: 3
          }
          this.sureOrder(result).then((res) => {
            if (res.code == 200) {
              this.getDetail()
              this.confirm = false
            }
          })
        })
        .catch(() => {
          // on cancel
        })
    },
    goPay () {
      this.getDetail()
      this.show = true
    },
    //待支付确认
    paySure () {
      this.checkbyId({ pids: this.flProductId }).then(res => {
        if (res.code == 200) {
          this.paymentMethod()

        } else {
          Toast(res.msg)
        }
      })


    },
    paymentMethod () {
      var that = this

      var ischeck
      if (that.radio == '1') {
        ischeck = 0
        var data = {
          pids: that.flProductId,
          price: that.flChildOrderTotalAmount,
          type: ischeck,
          aid: that.detail.flChildOrderAddressId,
          payType: that.detail.payType,
          orderNo: that.flChildOrderNo
        }
        that.subOrder(JSON.stringify(data)).then((res) => {
          if (res.code == 200) {
            Toast({
              message: '支付成功', onClose: function () {
                localStorage.removeItem('pid')
                that.$router.push({ name: 'myOrder' })
              }
            })
          } else {

            Toast({
              message: res.msg, onClose: function () {
                that.$router.push({ name: 'myOrder' })
              }
            })

          }

        })
      } else {

        ischeck = 1
        var data = {
          pids: that.flProductId,
          price: that.flChildOrderTotalAmount,
          type: ischeck,
          aid: that.detail.flChildOrderAddressId,
          payType: that.detail.payType,
          orderNo: that.flChildOrderNo
        }
        that.subOrder(JSON.stringify(data)).then((res) => {
          if (res.code == 200) {
            var thedata = JSON.parse(res.data.param)
            //微信支付
            WeixinJSBridge.invoke(
              'getBrandWCPayRequest', {
              "appId": thedata.appId,     //公众号名称，由商户传入
              "timeStamp": thedata.timeStamp,         //时间戳，自1970年以来的秒数
              "nonceStr": thedata.nonceStr, //随机串
              "package": thedata.package,
              "signType": thedata.signType,         //微信签名方式：
              "paySign": thedata.paySign //微信签名
            },
              function (res) {
                if (res.err_msg == "get_brand_wcpay_request:ok") {
                  localStorage.removeItem('pid')
                  that.$router.push({ name: 'myOrder' })
                  // 使用以上方式判断前端返回,微信团队郑重提示：
                  //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
                } else {
                  Toast({
                    message: '支付失败', onClose: function () {
                      that.$router.push({ name: 'myOrder' })
                    }
                  })

                }
              })
          } else {
            Toast({
              message: res.msg, onClose: function () {
                that.$router.push({ name: 'myOrder' })
              }
            })

          }
        })
      }

    },
    edit () {
      this.$router.push({ path: '/addressList', query: { back: 3, orderBack: true, orderId: this.$route.query.id } })
    },
    del () {
      Dialog.confirm({
        confirmButtonText: '确定取消',
        cancelButtonText: '暂不取消',
        message: '是否取消该订单',
      })
        .then(() => {
          // on confirm
          var result = {
            flChildOrderId: this.flChildOrderId,
            flChildOrderState: 4
          }
          this.sureOrder(result).then((res) => {
            if (res.code == 200) {
              this.$router.push({ name: 'myOrder' })
              // this.$router.push({path: '/addressList');
            }
          })

        })
        .catch(() => {
          // on cancel
        })
    },
    //再次购买
    againBuy (id) {
      this.addCart({
        pid: id
      }).then(res => {
        if (res.code == 200) {

          this.$router.push({ name: 'shopCarList', query: { againBuy: true } })
        } else {
          Toast(res.msg)
        }
      })
    },
    copy (event) {
      var lassname = '.' + event.target.className
      this.$toCopy(lassname)
    },
    getDetail () {
      var orderID = ''
      if (this.$route.query.id != '' && this.$route.query.id != undefined && this.$route.query.id != null) {
        orderID = this.$route.query.id
      } else {
        orderID = localStorage.getItem('orderId')
      }
      var data1 = {
        flChildOrderNo: orderID,
        shoppingAddressId: this.$route.query.addressId,
        flChildOrderState: 0
        // flChildOrderState:this.$route.query.state,
      }
      this.listVoResult(data1).then(res => {
        if (res.code == 200) {
          if (res.data.flChildOrderState == 0) {
            //交易关闭时间=申请时间+24小时 - 当前时间
            var enddate = new Date(res.data.flChildOrderPayTime)
            enddate.setDate(enddate.getDate() + 1)
            var times = enddate.getTime() - new Date().getTime()
            this.time = times
          } else {
            //自动确认收货时间=发货时间 + 15天 - 当前时间
            var enddate = new Date(res.data.flChildOrderDeliveryTime)
            enddate.setDate(enddate.getDate() + 15)
            var times = enddate.getTime() - new Date().getTime()
            this.time = times
          }
          this.detail = res.data
          this.userAccountBanlance = parseFloat(res.data.userAccountBanlance).toFixed(2)
          this.flChildOrderTotalAmount = parseFloat(res.data.flChildOrderTotalAmount).toFixed(2)
          if (this.userAccountBanlance - this.flChildOrderTotalAmount < 0) {

            this.radio = '2'

          } else {
            this.radio = '1'

          }
          this.flProductId = res.data.flProductId
          this.flChildOrderId = res.data.flChildOrderId
          this.name = res.data.flChildOrderAddressName
          this.address = res.data.flChildOrderAddress
          this.phone = res.data.flChildOrderAddressPhone
          this.productName = res.data.flChildOrderProductName
          this.productPrice = res.data.flProductPrice
          this.flChildOrderNumber = res.data.flChildOrderNumber
          this.flChildOrderNo = res.data.flChildOrderNo
          this.flChildOrderPayTime = res.data.flChildOrderPayTime
          this.flOrderPayType = res.data.flOrderPayType
          this.flChildOrderLogistics = res.data.flChildOrderLogistics
          this.flProductHomeImage = res.data.flProductHomeImage
          this.flChildOrderLogistics = res.data.flChildOrderLogistics
          this.OrderState = res.data.flChildOrderState
          this.flChildOrderExpress = res.data.flChildOrderExpress
          this.a = '积分支付(可用积分' + this.userAccountBanlance + ')'
          //快递方式
          if (this.flChildOrderExpress == null) {
            this.flChildOrderExpress = '未知'
          }

          if (this.flOrderPayType == 0) {
            this.jiFen = res.data.flChildOrderTotalAmount
            this.lastMoney = '0.00'
          } else {
            this.lastMoney = res.data.flChildOrderTotalAmount
            this.jiFen = '0.00'
          };
          //页面显示
          //待收货
          if (res.data.flChildOrderState == 2) {
            this.confirm = true
            this.cancel = false
            //代付款
          } else if (res.data.flChildOrderState == 0) {
            this.lastMoney = res.data.flChildOrderTotalAmount
            this.noNeed = false
            this.need = true
            this.Payment = false
            this.integral = false
            this.Package = false
            this.cancel = true
            this.fix = true
            this.again = false
            this.pay = true
          }
          //取消订单
          else if (res.data.flChildOrderState == 4) {
            this.jiFen = '0.00'
            this.Payment = false
            this.noNeed = false
            this.Package = false
          }
          else {
            this.Package = false,
              this.confirm = false
            this.cancel = false
          }
          //订单状态
          if (res.data.flChildOrderState == 0) {
            this.flChildOrderState = '待支付'
          } else if (res.data.flChildOrderState == 1) {
            this.flChildOrderState = '待发货'
          } else if (res.data.flChildOrderState == 2) {
            this.flChildOrderState = '待收货'
          } else if (res.data.flChildOrderState == 3) {
            this.flChildOrderState = '已完成'
          } else {
            this.flChildOrderState = '订单已取消'
          }

          //支付方式
          if (res.data.flOrderPayType == 0) {
            this.flOrderPayType = '积分'
          } else if (res.data.flOrderPayType == 1) {
            this.flOrderPayType = '微信'
          }


        }
      })


    },
  },
}
